.drop-down {
  .default-option {
    color: #707070;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    line-height: 24px;
  }

  .selected-option {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-style: normal;
  }
}
