.connected-radar-settings-list-grid {
  padding: 0 30px 0 15px;

  .connected-radar-settings-list {
    .ui.scuf-dropdown-wrapper {
      width: 100%;
    }
    .inputLabelInfo {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.connected-radar-settings-section {
  .connected-radar-settings-block {
    padding: 0 30px;
    .ui.radio.checkbox {
      margin-right: 44px;
    }

    .col-12 {
      padding-bottom: 8px;
      .ui.single-input {
        width: 40% !important;

        .input-wrap {
          width: 100% !important;
          .input-box {
            width: 100% !important;
          }
        }
      }
      .ui.scuf-dropdown-wrapper {
        width: 40% !important;
      }
    }
    .col-6 {
      padding-bottom: 8px;
      .ui.single-input {
        width: 82% !important;

        .input-wrap {
          width: 100% !important;
          .input-box {
            width: 100% !important;
          }
        }
      }
    }
  }
}
