.configure-form-footer {
  padding: 48px 40px 79.27px 30px !important;

  .configure-form-footer-text {
    color: #707070;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
}

.configure-form-footer-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px !important;

  .configure-form-footer-btn {
    height: 45px;
    width: 200px;
  }

  .ui.button + .ui.button {
    margin-left: 19px;
  }
}

.form-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
