.common-header {
  background-color: #ffffff;
  position: relative;

  .tab {
    margin: 0;
    margin-left: 530px !important;
  }

  .tab-agent {
    margin: 0;
    margin-left: 700px !important;
  }

  .dropdown-wrapper {
    position: absolute;
    margin-top: 8px;
    width: 368px;
    height: 48px;
    margin-left: 58.5px;
  }

  .need-help-wrapper {
    position: absolute;
    right: 100px;
    height: 48px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    text-decoration: underline;
    font-weight: 600;
  }

  .breadcrumb {
    margin-left: 0px;

    .breadcrumb-link {
      color: #000;
    }
  }

  .tab-pane {
    min-height: 563.5px !important;
    background-color: #f7f7f7 !important;
    border-top: 2px solid #e7e7e7;
    padding-bottom: 104.02px;
    padding-left: 58.5px;
    padding-right: 46.5px;
    position: relative;

    .config-header {
      color: #606060;
      font-size: 35px;
      font-weight: 300;
      line-height: 26px;
      text-align: center;
      padding-bottom: 48px;
      padding-top: 35.5px;
    }
  }
}
