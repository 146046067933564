body,
html,
#root {
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

p,
h5,
h1,
.ui.scuf-grid {
  margin: 0;
  padding: 0;
}

.col-no-padding {
  padding: 0;
}

.ui.page-header .page-header-menu.ui.menu .user-profile-menu .menu > a.item:hover {
  font-weight: bold !important;
  padding-left: 28px !important;
  border-left: 4px solid #1274b7;
}

.ui.forge-datatable-wrap .p-datatable .p-selection-column .p-checkbox-box {
  border-radius: 1.5rem;
  background: white;

  &.p-highlight {
    border: 1px solid black;
    background-color: white;
  }
}

.ui.forge-datatable-wrap .p-datatable .p-selection-column .p-checkbox-icon.pi-check {
  height: 100%;

  &:before {
    content: '' !important;
    display: inline-block;
    border-radius: 1.5rem;
    height: 1rem;
    width: 1rem;
    background-color: #1274b7 !important;
  }
}

div.ui.pointing.secondary.ui.scuf-tab.menu a.item {
  line-height: 40px !important;

  &.active {
    padding-bottom: 7px !important;
    border-bottom: 5px solid #1274b7 !important;
  }
}

.ui.forge-datatable-wrap .p-datatable.select-software .p-selection-column {
  .p-checkbox-box {
    border-radius: 0;
    background-color: white;
  }

  .p-checkbox-icon.pi-check:before {
    content: '\ea56' !important;
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 0;
    background-color: #1274b7 !important;
  }
}

.ui.forge-datatable-wrap .p-datatable.select-software .p-selection-column .p-checkbox-box.p-highlight {
  border: none;
}

.ui.forge-datatable-wrap .p-datatable.select-software .p-datatable-header .action-bar {
  display: flex;
  justify-content: space-between;

  .close-btn {
    display: none;
  }

  .totals {
    display: flex !important;
  }
}
