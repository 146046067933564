.aircraft-details-columns {
  padding-bottom: 0;
}

.aircraft-details-grid {
  max-height: 380px;
  overflow-y: auto;
  position: relative;
}

.aircraft-details-row {
  .scuf-col.col-4 {
    padding-bottom: 0;
  }
}

.aircraft-details-grid::-webkit-scrollbar {
  width: 10px;
}

.aircraft-details-grid::-webkit-scrollbar-thumb {
  background: #606060 !important;
}

.aircraft-details-grid::-webkit-scrollbar-track {
  background: #fff;
  border: #d0d0d0 0.0625rem solid;
}
