.data-table-wrapper {
  width: 100%;

  .p-datatable-loading-content {
    margin-top: 70px !important;
  }

  .config-table-card {
    padding: 0 !important;
    margin: 0 !important;

    .config-table-card-content {
      height: 179.5px;
      text-align: center;
      padding-top: 49.5px;
      border: 1px solid #d0d0d0 !important;
      border-top: 0px !important;
      color: #b0b0b0 !important;
      font-size: 35px !important;
      font-weight: 300 !important;
      line-height: 42px !important;
    }
  }

  .hide-search-bar {
    .ui.single-input.global-filter {
      display: none !important;
    }
    .total-count {
      padding-left: 4rem !important;
    }
  }

  .filter-bar .menu-items {
    padding-right: 2rem !important;
  }

  .display-tbody-css {
    tbody.p-datatable-tbody {
      display: none;
    }
  }

  .p-datatable-wrapper-css {
    background-color: #ffffff;

    td > div.cell-data-wrap {
      color: #303030;
      font-weight: 500;
      line-height: 20px;
      padding-right: 1.5rem !important;
      font-size: 15px;
    }

    td,
    th.p-sortable-column {
      vertical-align: top;
    }

    th.p-sortable-column:nth-child(8),
    td:nth-child(8) {
      padding-right: 25px !important;

      .add {
        color: #1274b7;
        font-weight: 700;
        margin-top: 5px;
        cursor: pointer;
      }
    }

    .input-wrap {
      height: 48px;
      width: 368px;

      .input-box {
        width: 100%;
      }
    }
  }

  .header-bar-item-css {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 5px;

    .btn-icon {
      font-weight: 600;

      &.clone-icon {
        font-weight: normal;
      }
    }
  }
}

.upgrade-icon-btn::before {
  content: url('./upgrade.png');
  display: inline-block;
  margin-right: 5px;
  height: 25px;
  width: 25px;
}

.add.table-icon {
  color: #1274b7;
  font-weight: 700;
  margin-top: 5px;
  cursor: pointer;
}

.config-table .p-datatable-header .action-bar .totals {
  display: none !important;
}

.ui.forge-datatable-wrap .p-datatable .p-datatable-header .action-bar {
  display: flex;
  justify-content: flex-end;
}

.ui.forge-datatable-wrap .p-datatable .p-datatable-header .action-bar .close-btn {
  position: absolute;
  left: 10px;
}

.config-table {
  .p-datatable-loading {
    .p-datatable-loading-overlay.p-component-overlay {
      z-index: 9;
      background-color: rgba(0, 0, 0, 0.9);
    }
  }
}
