div.selected.item {
  &:hover {
    background-color: #f0f0f0 !important;
  }

  h5 {
    font-weight: 400;
    padding-top: 5px;

    &:first-child {
      font-weight: 700;
    }
  }
}
