.display-settings-list-grid {
  padding: 0 30px 0 15px;

  .display-settings-list {
    .ui.scuf-dropdown-wrapper {
      width: 100%;
    }
  }
}

.display-settings-section {
  .display-settings-block {
    padding: 0 30px;
    .display-settings-list {
      .h-icon.common {
        padding-top: 4px;
        padding-left: 4px;
        font-weight: 600 !important;
      }
      .inputLabelInfo {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .ui.scuf-dropdown-wrapper {
      width: 40% !important;
    }
    .ui.radio.checkbox {
      margin-right: 44px;
    }

    .col-12 {
      .ui.single-input {
        width: 40% !important;

        .input-wrap {
          width: 100% !important;
          .input-box {
            width: 100% !important;
          }
        }
      }
      .ui.scuf-dropdown-wrapper {
        width: 40% !important;
      }
    }
    .col-6 {
      .ui.single-input {
        width: 82% !important;

        .input-wrap {
          width: 100% !important;
          .input-box {
            width: 100% !important;
          }
        }
      }
      .ui.scuf-dropdown-wrapper {
        width: 80% !important;
      }
    }
  }
}
