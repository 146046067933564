.select-box {
  width: 368px !important;
  margin: 10px 0px;
}

.same-row-padding {
  padding-left: 37px;
}

.confirm-stc-heading h3 {
  color: #303030;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.25px;
  line-height: 26px;
}
