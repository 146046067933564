.ui.small.modal.adg-modal {
  width: 55% !important;
}

.adg-modal-content {
  margin-top: 0px !important;
  background-color: #fff !important;
}

.adg-heading {
  padding-left: 0 !important;
  margin: 0 10px !important;
  font-weight: 600;
}

.adg.footer-action {
  padding: 2.5rem 0 0 !important;
  button {
    width: 150px;
    padding: 12px 10px !important;
  }
}

.agent-modal-grid {
  margin-top: 20px !important;
}

.agent-modal-grid .scuf-col .col-12 {
  padding: 0px !important;
}
