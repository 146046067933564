.software-details {
  .scuf-list-wrapper {
    .item {
      .content {
        padding: 0;
        .header {
          padding: 0;
        }
      }
    }
  }
}

.select-software-heading {
  margin-bottom: 25px;
  padding-left: 10px;
}

.action-item-text {
  font-style: italic;
}

.ui.err-message {
  font-family: 'Honeywell Sans Web', Arial, Helvetica, sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #be271c;
  font-style: italic;
  float: left;
}

.aircraft-table-software {
  position: relative;

  thead {
    position: relative;
  }

  .table-header {
    background-color: transparent;

    .table-labels {
      color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(-25%);
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      padding: 0 25px;
      font-family: 'Honeywell Sans Web', Arial, Helvetica, sans-serif;
      letter-spacing: 0px;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: capitalize !important;

      .info {
        &-remaining {
          float: right;
          font-style: italic;
          padding: 0 10px;
        }

        &-available,
        &-selected {
          float: left;
          padding: 0 10px;
        }

        &-available {
          border-right: 1px solid #fff;
        }
      }
    }

    &.active::after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #1274b7;
      content: '';
    }
  }
}

.select-features-table {
  tr td {
    padding: 1rem 0.5rem 1rem 1rem !important;
  }
}
