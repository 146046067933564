.software-features-list-grid {
  padding: 0 30px 0 15px;

  .software-features-list {
    .ui.radio.checkbox {
      margin-right: 44px;
    }

    .ui.scuf-dropdown-wrapper {
      width: 100%;
    }
  }
}

.software-feature-header {
  width: 100% !important;
  height: 25px;
  margin-bottom: 2rem;
}

.incompatible-msg {
  color: red;
  font-size: 12px;
}

.software-features-section {
  .software-display-block {
    display: block;
    padding: 0 30px;
  }
}
