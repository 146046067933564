.order-details {
  height: 165px;
  background-color: #f0f0f0;

  .ui.fitted.divider {
    margin: 0 auto;
    width: 80%;
  }

  &-content {
    margin: 0 auto;
    width: 100%;
    margin-top: 15px;

    &-label {
      margin-left: 15%;
      text-align: right;
      float: left;
      h5 {
        color: #606060;
        font-size: 12px !important;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
      }
    }

    &-data {
      margin-left: 2%;
      width: 50%;
      float: left;
      color: #606060;
      font-size: 12px !important;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;

      &-purchase {
        width: 100%;
        float: left;
        &-qty {
          float: left;
          width: 50%;

          &-data {
            padding-left: 30px;
          }
        }
        &-item {
          float: left;
          width: 50%;
        }
      }
    }
  }
}
