.save-header h3 {
  color: #303030;
  font-size: 18px;
  letter-spacing: 0.25px;
  line-height: 26px;
  margin-bottom: 25px;
}

.card-border {
  border: 1px solid #d0d0d0 !important;
  input {
    width: 400px !important;
  }
}

.ui.card .content.footer {
  border: none !important;
  margin-bottom: 30px;

  .save-config-btn {
    width: 200px;
  }
}

.save-page-input {
  margin-top: 57px;
  display: block;
}
