.card-border {
  border: 1px solid #d0d0d0 !important;

  h1 {
    color: #303030;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-left: 10px;
  }
}

.card-body {
  p {
    color: #707070;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 15px 0px;
  }

  a {
    color: #1274b7;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
}

.ui.card .content.footer {
  border: none !important;
  .h-icon {
    color: #fff !important;
  }
}

.ui.card .content.footer.download-configuration {
  display: block;
  position: relative;

  .adg-lite-link {
    float: left;
    margin-top: 15px;
    color: #1274b7;
    font-weight: 600;
    text-decoration: underline;
    letter-spacing: 0.2px;
    cursor: pointer;
  }

  .file-download {
    width: 300px;
    height: 48px;
    float: right;
    .download-icon {
      margin-right: 10px !important;
      margin-left: 0 !important;
    }
    &.disabled {
      padding: 10px 0 !important;
    }
  }
}
