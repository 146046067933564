.error-card {
  color: #303030;

  .header {
    padding-left: 38px !important;
  }

  .content {
    padding: 33px 81px 39px 111px !important;

    p {
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 25px;
    }

    .ui.button.primary {
      display: flex;
      margin: 0 auto;
    }
  }
}
