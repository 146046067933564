.bulk-upload-section {
  .bulk-upload-btn {
    float: right;
    padding: 12px 20px;
  }
}
.modals.dimmer .ui.scrolling.modal.bulk-upload-modal {
  margin-bottom: -5% !important;
}

.bulk-upload-modal-text {
  font-size: 1rem;
  font-weight: 600;
}

.download-template {
  color: #007ad9;
  font-weight: 600;
}

.download-template:hover {
  cursor: pointer;
}

.download-template-icon {
  margin-right: 8px;
}
