.ui.small.modal.agent-modal {
  width: 55% !important;
}

.clone-modal-content {
  margin-top: 0px !important;
  background-color: #fff !important;
}

.ui.search-wrap .ui.search.ui-search .ui.input {
  min-width: 300px !important;
}

.ui.search-wrap .ui.search.ui-search .results.visible {
  display: none !important;
}

.customer-account-list .header {
  padding: 0 0 0 0 !important;
  font-weight: 700 !important;
}

.customer-account-list {
  min-height: 200px !important;
  max-height: 350px !important;
  overflow-y: scroll;
  border: 5px solid #f0f0f0 !important;
}

.active-account {
  background-color: #e0e0e0 !important;

  .content {
    background-color: #e0e0e0 !important;
  }
}

.scuf-listitem-wrapper.onclick :hover {
  background-color: #e0e0e0 !important;

  .content {
    background-color: #e0e0e0 !important;
  }
}

.header-label.clone-heading {
  padding-left: 0 !important;
  margin: 0 10px !important;
}

.ui.card.raised.agentCard {
  box-shadow: none !important;
  padding: 0px !important;
}

.footer-action {
  padding: 2.5rem 0 0 !important;
}

.agent-modal-grid {
  margin-top: 20px !important;
}

.agent-modal-grid .scuf-col .col-12 {
  padding: 0px !important;
}

.header-label {
  padding: 0px 10px 12px 12px !important;
  margin-left: 10px !important;
}

.ui.card.raised.agentListCard {
  box-shadow: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

.customer-account-list::-webkit-scrollbar {
  width: 10px;
}

.customer-account-list::-webkit-scrollbar-thumb {
  background: #606060 !important;
}

.customer-account-list::-webkit-scrollbar-track {
  background: #fff;
  border: #d0d0d0 0.0625rem solid;
}

.clone-label {
  margin-left: 15px;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 15px;
}
