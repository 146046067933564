.modal-content {
  background-color: #f7f7f7 !important;
  padding: 9px 11.2px 27px 11.56px !important;
  margin-bottom: 50px;
  margin-top: 45px;
}

.card {
  padding: 12px 15.2px 39px 16.8px !important;
}
